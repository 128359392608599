@import '../../../scss/mixins';

.Grid {
  &___one {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }

  &___two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
  }

  &___twoByThree {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }

  &___three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    width: 100%;

    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
  }

  &___oneByThree {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    width: 100%;

    @include breakpoint(md) {
      grid-template-columns: 1fr;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
  }

  &___four {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    width: 100%;

    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xs) {
      grid-template-columns: 1fr;
    }
  }

  &___five {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    width: 100%;

    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xs) {
      grid-template-columns: 1fr;
    }
  }
  &___filtergrid{
    display: grid;
    // grid-template-columns: 200px 2fr 700px 1fr;
    grid-template-columns: 192px repeat(2, 1fr) 400px;
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    width: 100%;
    
    @include breakpoint(md) {
      grid-template-columns: 1fr;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xs) {
      grid-template-columns:1fr;
    }
  }
  
}
