@import '../../scss/variables';
@import '../../scss/mixins';

.SubscriptionCard {
  align-items: center;
  border: 4px solid $border-300;
  color: #55595c;
  cursor: pointer;
  display: flex;
  height: 100px;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;

  @include breakpoint(md) {
    flex-direction: column;
    height: auto;
  }

  &:hover {
    border-color: $violet-400;
  }

  &___active {
    border-color: $violet-400;
  }

  &___disabled {
    cursor: not-allowed;
    border-color: $border-300 !important;
  }

  &_planName {
    align-items: center;
    background: $neutral-50;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    max-width: 150px;
    width: 100%;

    @include breakpoint(md) {
      max-width: 100%;
      padding: 10px 0;
    }
  }

  &_planDescription {
    max-width: 300px;
    text-align: center;
    width: 100%;

    @include breakpoint(md) {
      max-width: 100%;
      padding: 10px 0;
    }
  }

  &_planPrice {
    text-align: center;
    max-width: 150px;
    width: 100%;

    @include breakpoint(md) {
      max-width: 100%;
      padding: 10px 0;
    }
  }
}