@import '../../../scss/mixins';
@import '../../../scss/variables';

.ScheduleCard {
  border: 1px solid;
  border-radius: 16px;
  @include heading(1rem, 1.25);
  @include flex-center;
  text-transform: capitalize;
  cursor: pointer;
  background-color: $neutral-0;
  border-color: $neutral-200;
  color: $neutral-700;

  &:hover,
  &:focus,
  &:active {
    background-color: $neutral-50;
  }

  &___disabled {
    background-color: $neutral-0 !important;
    cursor: default;
  }

  &___toggled {
    @include flex-center;
    flex-direction: column;
    position: relative;
    border: 1px solid $violet-300 !important;
    padding: 0 8px;
  }

  &_close {
    color: $neutral-400;
    position: absolute;
    top: 4px;
    right: 4px;
  }

  &_time {
    @include flex-center;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    @include breakpoint(sm) {
      flex-direction: column;
    }
  }

  &_to {
    margin: 8px;
  }

  &_day {
    text-transform: capitalize;
    color: $violet-300;
    margin: 0 0 24px 0;
  }

  &_error {
    margin: 8px 0;
  }
}
