@import '../../../../scss/mixins';

.Preloader {
  @include ease(0.4s);
  height: 500px;
  margin-top: 40px;
  overflow: hidden;

  &_shine {
    background-size: 800px 500px;
    height: 500px;
  }
}