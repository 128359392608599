/** =================== OPEN SANS =================== **/

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/OpenSans-Regular.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../static/fonts/OpenSans-Light.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../static/fonts/OpenSans-LightItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../static/fonts/OpenSans-Italic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../static/fonts/OpenSans-Semibold.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../static/fonts/OpenSans-SemiboldItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/OpenSans-Bold.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../static/fonts/OpenSans-BoldItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../static/fonts/OpenSans-ExtraBold.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../static/fonts/OpenSans-ExtraBoldItalic.ttf') format('ttf');
  font-display: swap;
}

/** =================== MULI =================== **/

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/Muli-Regular.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 300;
  src: url('../static/fonts/Muli-Light.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 300;
  src: url('../static/fonts/Muli-LightItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: url('../static/fonts/Muli-Italic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 600;
  src: url('../static/fonts/Muli-SemiBold.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 600;
  src: url('../static/fonts/Muli-Semi-BoldItalic.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: url('../static/fonts/Muli-Bold.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 700;
  src: url('../static/fonts/Muli-BoldItalic.ttf') format('ttf');
  font-display: swap;
}

/** =================== MATERIAL ICONS =================== **/

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../static/fonts/MaterialIcons.otf');
  font-display: swap;
}