@import '../../scss/variables';
@import '../../scss/mixins';

.TimesheetGrid {
  border-top: 1px solid $neutral-50;
  overflow-x: auto;
  
  &_grid {
    display: grid;
    
    &___Daily {
      grid-template-columns: 50px 150px;
      max-width: 500px;
  
      @include breakpoint(sm) {
        grid-template-columns: 40px 1fr;
        width: auto;
      }
    }
  
    &___Weekly {
      grid-template-columns: 50px repeat(7, 1fr) minmax(90px, 1fr);
      @media only screen and (max-width: 1440px) {
        width: 1380px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 40px 1fr;
        width: auto;
      }
    }
  
    &___Fortnightly, &___Monthly {
      grid-template-columns: 50px 80px repeat(7, 150px) minmax(90px, 1fr);
      @media only screen and (max-width: 1520px) {
        width: 1460px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 40px 1fr;
        width: auto;
      }
    }

    &_bodyWrapper {
      max-height: calc(60vh - 20px);
      overflow-y: overlay;
    }
  }

  &_boldText {
    font-weight: 700;
  }

  &_centerText {
    text-align: center !important;
  }

  &_clickable {
    cursor: pointer;

    &___active {
      background-color: $blue-50 !important;
    }
  }

  &_column {
    align-items: center;
    background-color: $neutral-0;
    border: .5px solid $border-200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 0;
    min-width: 0;
    padding: 30px 8px;
    text-align: center;
    word-break: break-word;

    @include breakpoint(sm) {
      padding: 8px;
    }

    p {
      @include breakpoint(sm) {
        font-size: 1rem !important;
      }
    }

    &___active {
      background-color: $blue-0;
      padding: 8px;
      text-align: left;
    }

    &_even {
      background-color: $blue-25;
    }
  }

  &_header {
    background-color: $violet-400;
    color: $neutral-0;
    padding: 18px 8px;

    @include breakpoint(sm) {
      padding: 8px;
    }

    &_clickable {
      background-color: $blue-400;
    }

    &_total {
      background-color: $blue-400;
    }
  }

  &_keyWrapper {
    display: contents;
  }

  &_withMargin {
    margin-top: 20px;
  }

  &_overrideIcon {
    color: $neutral-400;
    padding: 0;
  }
}