@import '../../scss/variables';

.Editable {
  &_displayValue {
    color: $blue-400;
    max-width: 100%;
    width: max-content;
    word-break: break-word;
  }

  &_input {
    border: 1px solid $neutral-700;
    outline: none;
    width: 100%;
  }

  &_textarea {
    border: 1px solid $neutral-700;
    height: 150px;
    outline: none;
    resize: none;
    width: 100%;
  }

  &_editButton {
    background: none;
    border: 0;
    border-radius: 4px;
    color: $neutral-0;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    width: max-content;
  
    &_save {
      background-color: $green-400;
    }
  
    &_cancel {
      background-color: $red-400;
    }
  }
}