@import '../../../../../scss/mixins';
@import '../../../../../scss/variables';

.PreloaderLarge {
  @include ease(0.4s);
  margin-top: 30px;
  overflow: hidden;

  &_header, &_body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 33.33%);
    }
  }

  &_header {
    &_shine {
      height: 54px;
    }
  }

  &_separator {
    border-color: $neutral-0;
  }

  &_body {
    &_shine {
      height: 94px;
    }
  }
}