@import '../../../scss/variables';
@import '../../../scss/mixins';

.ViewReview {
  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &_reportButtons {
    align-items: center;
    display: flex;

    &_button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &_text {
        @include breakpoint(md) {
          display: none;
        }
      }

      i {
        @include breakpoint(md) {
          margin: 0;
        }
      }
    }
  }

  &_secondRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 93px;

    @include breakpoint(sm) {
      margin-top: 143px;
    }

    &_searchInput {
      margin-left: auto;
      width: 250px;
    }
  }

  &_mainRow {
    display: grid;
    grid-column-gap: 60px;
    grid-template-columns: 1fr 1fr;
    margin-top: 32px;

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
    }

    &_firstGrid {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr;

      &_value {
        margin-top: 10px;
      }
    }

    &_secondGrid {
      background: $neutral-0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 40px;

      > div {
        border: 1px solid $border-300;
        padding: 20px;
      }

      &_value {
        display: inline-block;
      }

      &_icon {
        border-radius: 50%;
        color: $neutral-0;
        margin-right: 10px;

        &___green {
          background: $green-400;
        }

        &___red {
          background: $red-400;
        }
      }
    }
  }
}