@import '../../scss/variables';
@import '../../scss/mixins';

.Breadcrumbs {
  &_links {
    display: flex;
    margin-top: 8px;

    @include breakpoint(xs) {
      max-width: 300px;
      overflow-x: auto;
      white-space: nowrap;
    }

    &_keyWrapper {
      display: contents;
    }

    &_link {
      color: $blue-400;
      text-decoration: none;
    }

    &_linkSeparator {
      margin: 0 10px !important;
    }
  }
}