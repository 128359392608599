@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.PreloaderProject {
  @include ease(0.4s);
  margin-top: 73px;
  overflow: hidden;

  &_header, &_body {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &_header {
    &_shine {
      height: 54px;

      @include breakpoint(sm) {
        display: none;
      }
  
      &:nth-child(1) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(2) {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }

  &_separator {
    border-color: $neutral-0;
  }

  &_body {
    &_shine {
      height: 94px;

      @include breakpoint(sm) {
        display: none;
      }
  
      &:nth-child(1) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(2) {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }
}