@import '../../../scss/variables';
@import '../../../scss/mixins';

@mixin base {
  @include body(0.75rem, 1.25);
  color: $neutral-700;

  @content;
}

.Label {
  @include base;

  &___disabled {
    @include base {
      opacity: 0.5;
    }
  }
}
