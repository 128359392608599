@import '../../scss/variables';
@import '../../scss/mixins';

.TimesheetGrid {
  border-top: 1px solid $neutral-50;
  overflow-x: auto;
  
  &_grid {
    display: grid;
    
    &___Daily {
      grid-template-columns: 60px minmax(90px, 5fr) minmax(130px, 2fr) 250px;
      max-width: 550px;
  
      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }

    &___Daily_narrow {
      grid-template-columns: 60px minmax(90px, 5fr) minmax(130px, 2fr) 250px;
      max-width: 550px;
  
      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }
  
    &___Weekly {
      // grid-template-columns: 50px minmax(90px, 1fr) minmax(90px, 1fr) repeat(7, 150px) minmax(90px, 1fr);
      grid-template-columns: 50px minmax(90px, 0fr) minmax(90px, 0.5fr) repeat(7, 190px) minmax(90px, 0.5fr);
      @media only screen and (max-width: 1440px) {
        width: 1650px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }

    &___Weekly_narrow {
      // grid-template-columns: 50px minmax(90px, 1fr) minmax(90px, 1fr) repeat(7, 170px) minmax(90px, 1fr);
      display: grid;
      grid-template-columns: 0.5fr minmax(90px, 0.5fr) minmax(90px, 0.8fr) repeat(7, 0.5fr) minmax(90px, 0.6fr);
      overflow-x: auto;
      width: 100%;
      @media only screen and (max-width: 1440px) {
        // width: 1650px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }
  
    &___Fortnightly {
      // grid-template-columns: 50px minmax(90px, 1fr) minmax(90px, 1fr) 80px repeat(7, 150px) minmax(90px, 1fr);
      grid-template-columns: 50px minmax(90px, 0.5fr) minmax(90px, 1fr) 80px repeat(7, 179px) minmax(90px, 1fr);
      @media only screen and (max-width: 1520px) {
        width: 1650px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }

    &___Fortnightly_narrow {
      // grid-template-columns: 50px minmax(90px, 1fr) minmax(90px, 1fr) 80px repeat(7, 150px) minmax(90px, 1fr);
      // grid-template-columns: 50px minmax(90px, 0.5fr) minmax(90px, 0.7fr) 80px repeat(7, 149px) minmax(90px, 1fr);
      display: grid;
      grid-template-columns: 0.5fr minmax(90px, 0.5fr) minmax(90px, 0.8fr) repeat(8, 0.5fr) minmax(90px, 0.6fr);
      overflow-x: auto;
      width: 100%;
      @media only screen and (max-width: 1520px) {
        // width: 1650px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }
  
    &___Monthly {
      // grid-template-columns: 50px minmax(90px, 1fr) minmax(90px, 1fr) 80px repeat(7, 150px) minmax(90px, 1fr);
      grid-template-columns: 50px minmax(90px, 0.5fr) minmax(90px, 1fr) 79px repeat(7, 180px) minmax(90px, 1fr);
      @media only screen and (max-width: 1520px) {
        width: 1650px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }

    &___Monthly_narrow {
      // grid-template-columns: 50px minmax(90px, 1fr) minmax(90px, 1fr) 80px repeat(7, 150px) minmax(90px, 1fr);
      // grid-template-columns: 50px minmax(90px, 0.5fr) minmax(90px, 1fr) 79px repeat(7, 180px) minmax(90px, 1fr);
      display: grid;
      grid-template-columns: 0.5fr minmax(90px, 0.5fr) minmax(90px, 0.8fr) repeat(8, 0.5fr) minmax(90px, 0.6fr);
      overflow-x: auto;
      width: 100%;
      @media only screen and (max-width: 1520px) {
        // width: 1650px;
      }

      @include breakpoint(sm) {
        grid-template-columns: 30px 18% 27% calc(55% - 30px);
        width: auto;
      }
    }

    &_bodyWrapper {
      max-height: calc(60vh - 20px);
      overflow-y: overlay;
    }
  }

  &_boldText {
    font-weight: 700;
  }

  &_centerText {
    text-align: center !important;
  }

  &_clickable {
    cursor: pointer;

    &___active {
      background-color: $blue-50 !important;
    }
  }

  &_column {
    align-items: center;
    background-color: $neutral-0;
    border: .5px solid $border-200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 0;
    min-width: 0;
    padding: 30px 8px;
    text-align: center;
    word-break: break-word;

    @include breakpoint(sm) {
      padding: 8px;
    }

    p {
      @include breakpoint(sm) {
        font-size: 1rem !important;
      }
    }

    &___active {
      background-color: $blue-0;
      padding: 8px;
      text-align: left;
    }

    &_even {
      background-color: $blue-25;
    }
  }

  &_header {
    background-color: $violet-400;
    color: $neutral-0;
    padding: 18px 8px;

    @include breakpoint(sm) {
      padding: 8px;
    }

    &_clickable {
      background-color: $blue-400;
    }

    &_total {
      background-color: $blue-400;
    }
  }

  &_keyWrapper {
    display: contents;
  }

  &_withMargin {
    margin-top: 20px;
  }

  &_overrideIcon {
    color: $neutral-400;
    padding: 0;
  }
}