@import '../../../scss/variables';
@import '../../../scss/mixins';

.Clients {
  &_firstRow {
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }
  &_reportButtons {
    display: flex;

    &_button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &_text {
        @include breakpoint(md) {
          display: none;
        }
      }

      i {
        @include breakpoint(md) {
          margin: 0;
        }
      }
    }
  }
  &_secondRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 10rem;
    @media only screen and (min-width: 280px) and (max-width: 767px) {
      margin-top: 13rem !important;
    }
    @include breakpoint(lg) {
      flex-direction: column;
    }

    @include breakpoint(sm) {
      margin-top: 143px;
    }
  }
  
  &_navigationContainer {
    align-items: center;
    display: flex;

    @include breakpoint(lg) {
      flex-direction: column;
      margin-bottom: 24px;
    }

    &_navigation {
      align-items: center;
      display: flex;
      margin-left: 24px;

      @include breakpoint(lg) {
        margin-top: 24px;
      }

      @include breakpoint(xs) {
        margin-left: 0;
      }

      &_item {
        margin-right: 16px;
        width: auto !important;

        @include breakpoint(xs) {
          margin-right: 8px;
        }

        &:last-child {
          margin-right: 0;
        }

        &_buttonText {
          @include breakpoint(xs) {
            display: none;
          }
        }

        i {
          @include breakpoint(xs) {
            margin: 0;
          }
        }
      }
    }
  }
  &_withMargin {
    margin-top: 20px;
    margin: 0;
    position: fixed;
    right: 95px;
    height: 45px;
  }
  &_search {
    display: flex;
  }
  &_viewClientDiv {
    display: flex;
    float: right;
    margin-top: -4rem;
 
  }
  &_searchBoxInput input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  &_searchButton {
    height: 50.5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    padding: 10% 5.5%;
    & i{
      font-size: 22px;
    }
  }
  &_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 3rem;

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 33.33%);
      // margin-top: 143px;
    }

    &___Employee {
      margin-top: 93px !important;
      @media only screen and (min-width: 280px) and (max-width: 767px) {
        margin-top: 105px !important;
      }
    }

    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;
      word-break: break-word;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }

      &_action {
        background-color: $blue-400;
      }

      &:nth-child(1) {
        @include breakpoint(sm) {
          grid-row: 1 / 5;
          grid-column: 1;
        }
      }

      &:nth-child(2) {
        @include breakpoint(sm) {
          grid-row: 1;
          grid-column: 2;
        }
      }

      &:nth-child(3) {
        @include breakpoint(sm) {
          grid-row: 2;
          grid-column: 2;
        }
      }

      &:nth-child(4) {
        @include breakpoint(sm) {
          grid-row: 3;
          grid-column: 2;
        }
      }

      &:nth-child(5) {
        @include breakpoint(sm) {
          grid-row: 4;
          grid-column: 2;
        }
      }

      &:nth-child(6) {
        @include breakpoint(sm) {
          grid-row: 1 / 5;
          grid-column: 3;
        }
      }
    }

    &_viewButton {
      color: $green-400;
    }

    &_editButton {
      color: $blue-400;
      margin-left: 5px;
    }

    &_deleteButton {
      color: $red-400;
      margin-left: 5px;
    }
  }

  &_noClients {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }

    &_withMargin {
      margin-top: 10px;
    }
  }

  &_keyWrapper {
    display: contents;
  }
}