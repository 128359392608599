@import '../../scss/variables';

.Dropzone {
  background: rgba(244, 242, 251, 0.5);
  border: 5px dashed rgba(207, 207, 207, 0.5);
  cursor: pointer;
  height: 250px;
  position: relative;
  text-align: center;
  width: 100%;

  &___active {
    background-color: #F4F2FB;
    border: 5px solid transparent;
  }

  &_input {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &_title {
    color: $neutral-500;
  }

  &_helperText {
    color: $neutral-300;
    margin-top: 10px;
  }

  &_thumbContainer {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    height: 100%;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;

    &___active {
      background-color: #F4F2FB;
    }

    &_img {
      max-height: 100%;
      object-fit: contain;
      max-width: 100%;
      min-width: 200px;
    }
  }

  &_buttons {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;

    &_close {
      background-color: $red-200;
      border-radius: 50%;
      font-weight: 700;
      padding: 8px;
    }
  }
}