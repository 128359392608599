@import '../../../scss/variables';
@import '../../../scss/mixins';

.Dashboard {
  &_cards {
    margin-top: 20px;

    &_card {
      padding: 20px;

      &_grid {
        grid-template-columns: 1fr 0.3fr !important;
      }

      &_rightColumn {
        text-align: right;
      }

      &_icon {
        font-size: 3.5rem !important;

        &___green {
          color: $green-400 !important;
        }

        &___blue {
          color: $blue-400 !important;
        }

        &___violet {
          color: $violet-400 !important;
        }
      }
    }
  }

  &_withMargin {
    margin-top: 40px !important;
  }

  &_graph {
    margin-top: 20px;
    padding: 40px;
    width: 70%;

    @include breakpoint(lg) {
      width: 100%;
    }

    @include breakpoint(xs) {
      padding: 20px;
    }
  }
}