@import '../../scss/variables';

.Sidebar {
  background: $neutral-0;
  border-right: 1px solid $neutral-100;
  box-shadow: $shadow-normal;
  height: calc(100% - 65px);
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 65px;
  transition: transform .25s,
              opacity .25s,top .35s,
              height .35s,
              -webkit-transform .25s,
              -moz-transform .25s,
              -o-transform .25s;
  transform: translate3d(0, 0, 0);
  width: 150px;
  z-index: 1000;

  &___toggled {
    transform: translate3d(-150px, 0, 0);
  }
}