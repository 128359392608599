@import '../../../scss/variables';

.RosterCard {
  background-color: $yellow-75;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;

  &___blue {
    background-color: $blue-75;
  }

  &___pink {
    background-color: $pink-75;
  }

  &___violet {
    background-color: $violet-75;
  }

  &_schedule {
    margin-bottom: 15px;
  }

  &_textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_icons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0 auto;

    &_icon {
      color: $neutral-400;
      margin-left: 8px;
      padding: 0;
    }
  }
}