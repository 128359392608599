@import '../../../scss/variables';
@import '../../../scss/mixins';

.Employees {
  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;
    margin-top: 93px;

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 33.33%);
      margin-top: 143px;
    }

    &_helpIcon {
      cursor: pointer;
      display: flex;
      margin-left: 5px;
    }

    &_helpTooltip {
      background: $blue-400 !important;
      font-size: 14px !important;
      max-width: 260px;
      padding: 15px !important;
      text-align: left;
    }

    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;
      word-break: break-word;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }

      &_action {
        background-color: $blue-400;
      }

      &:nth-child(1) {
        @include breakpoint(sm) {
          grid-row: 1 / 7;
          grid-column: 1;
        }
      }

      &:nth-child(2) {
        @include breakpoint(sm) {
          grid-row: 1;
          grid-column: 2;
        }
      }

      &:nth-child(3) {
        @include breakpoint(sm) {
          grid-row: 2;
          grid-column: 2;
        }
      }

      &:nth-child(4) {
        @include breakpoint(sm) {
          grid-row: 3;
          grid-column: 2;
        }
      }

      &:nth-child(5) {
        @include breakpoint(sm) {
          grid-row: 4;
          grid-column: 2;
        }
      }

      &:nth-child(6) {
        @include breakpoint(sm) {
          grid-row: 5;
          grid-column: 2;
        }
      }

      &:nth-child(7) {
        @include breakpoint(sm) {
          grid-row: 6;
          grid-column: 2;
        }
      }

      &:nth-child(8) {
        @include breakpoint(sm) {
          grid-row: 6;
          grid-column: 2;
        }
      }

      &:nth-child(9) {
        @include breakpoint(sm) {
          grid-row: 1 / 8; 
          grid-column: 3;
        }
      }
    }

    &_deleteButton {
      color: $red-400;
    }
  }

  &_noEmployees {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }

    &_withMargin {
      margin-top: 10px;
    }
  }

  &_keyWrapper {
    display: contents;
  }

  &_welcomeLink {
    color: $blue-400;
    font-weight: 700;
  }

  &_welcomeText {
    text-align: center;
  }
}