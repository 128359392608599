.DatabaseExport {
  align-items: center;
  display: flex;
  height: calc(100vh - 95px);
  justify-content: center;
  
  &_card {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 400px;
    padding: 20px;
    width: 100%;
  }
}