@import '../../../scss/variables';

.GenerateContractorTimesheetReportModal {
  &_withMarginTop {
    margin-top: 20px;
  }

  &_withMarginBottom {
    margin-bottom: 20px;
  }

  &_totalIncomeEarned {
    color: $neutral-400;

    &_amount {
      color: $green-400;
      margin-left: 24px;
    }
  }
}