@import './variables';
@import './fonts';
@import './mixins';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 14px;
  height: 100%;
  width: 100%;

  @include breakpoint(xs) {
    height: auto;
  }
}

body {
  background-color: $border-200;
  color: $neutral-700;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

#root {
  height: 100%;

  @include breakpoint(xs) {
    height: auto;
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.placeholdereasy-edit-wrapper {
  color: $blue-400;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.easy-edit-button-wrapper {
  display: block;
}

.stylingeasy-edit-wrapper p {
  cursor: pointer;
  width: 100%;
}

.stylingeasy-edit-component-wrapper select {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ReactModal__Overlay--after-open {
  opacity: 1 !important;
}

// Stripe

.StripeElement {
	background-color: $neutral-0;
	border: 1px solid $neutral-300;
	border-radius: 4px;
	padding: 14px 16px;
	width: 100%;
}

.StripeElement--focus {
	border-color: $violet-400;
}

.StripeElement--invalid {
	border-color: $red-400;
}

// Image Gallery

.image-gallery-image {
  background-color: $neutral-300;
  height: 370px;
  object-fit: contain;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px;
  width: 40px;
}

.image-gallery-icon {
  outline: none !important;
}

.image-gallery-icon:hover {
  color: $violet-400;
}

.image-gallery-thumbnail {
  cursor: pointer;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border-color: $violet-400;
}