@import '../../../scss/variables';

.NoEmployer {
  align-items: center;
  display: flex;
  height: calc(100vh - 125px);
  justify-content: center;

  &_card {
    margin: 0 auto;
    max-width: 500px;
    padding: 50px 20px;
    text-align: center;
  }

  &_codeWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    &_code {
      background-color: $violet-400;
      padding: 15px 40px;

      &___generating {
        opacity: 0.5;
      }
    }

    &_refreshIcon {
      color: $neutral-400;
      margin-left: 10px;
    }
  }

  &_orText {
    border-bottom: 1px solid $border-300;
    border-top: 1px solid $border-300;
    margin: 40px 0;
    padding: 20px;
  }

  &_switchButton {
    margin: 0 auto;
  }
}