@import '../../../../../scss/mixins';

.Preloader {
  @include ease(0.4s);
  height: 45px;
  margin-bottom: 10px;
  overflow: hidden;

  @include breakpoint(sm) {
    max-width: 60px;
  }

  &_shine {
    height: 45px !important;
  }
}