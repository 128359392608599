@import '../../../scss/mixins';
@import '../../../scss/variables';

.CreateTimesheet {
  &_card {
    margin-top: 20px;
    max-width: 450px;
    padding: 20px;
  }

  &_withMargin {
    margin-top: 20px;
    margin-right: 8px;
  }
  &_withMarginDrop {
    margin-top: 20px;
    margin-right: 5px;
  }
  &_withCosts {
    margin-top: 20px;
  }
  &_withStartTime {
    align-items: center;
    display: grid;
    grid-template-columns: 0.97fr 0.1fr;
    &_action {
      color: $neutral-400;
      border: 1px solid;
      border-radius: 11%;
      height: 51px;
      width: 50px;
      margin-top: 20px;
    }
  }
  &_removeRightBorder {
    border-right-style: hidden;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
  }
  &_withCostItem {
    align-items: center;
    display: grid;
    grid-template-columns: 0.6fr 0.4fr 0.1fr;
    &_action {
      color: $neutral-400;
      height: 50px;
      width: 50px;
      margin-top: 20px;
    }
  }
  &_withCostDetail {
    align-items: center;
    padding: 10px;
    display: grid;
    grid-template-columns: 0.6fr 0.4fr 0.1fr;
    &_action {
      color: $neutral-400;
      height: 50px;
      width: 50px;
    }
  }
  &_addNew {
    & i {
      color: white;
      fill: 1;
    }
    background-color: #37bc9b;
    border-radius: 10%;
  }
  &_delete {
    & i {
      color: red;
      fill: 1;
    }
    border-radius: 10%;
  }

  &_buttonGroup {
    margin-top: 40px;

    &_button {
      width: 100%;
    }

    &_buttonText {
      @include flex-center;
    }

    &_spinner {
      margin-left: 12px;
      width: auto !important;
    }
  }

  &_withTooltip {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;

    &_tooltipLink {
      cursor: pointer;
      margin-left: auto;
    }

    &_tooltipIcon {
      color: $violet-400;
      font-size: 20px !important;
    }

    &_tooltip {
      background: $neutral-0 !important;
      box-shadow: $shadow-raised;
      color: $neutral-700 !important;
      font-size: 14px !important;
      max-width: 400px;
      padding: 15px !important;
      text-align: left;

      &::after {
        border-top-color: $neutral-0 !important;
      }
    }
  }

  &_summary {
    &_row {
      padding: 12px;

      &:first-child {
        margin-top: 20px;
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &_group {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    &_label {
      margin-bottom: 6px;
    }
  }
}