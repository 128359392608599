@import '../../../scss/variables';
@import '../../../scss/mixins';

.Radio {
  &___small {
    &___disabled {
      opacity: 0.5;
    }

    &_label {
      display: inline-block;
      position: relative;
      padding-left: 22px;
      user-select: none;

      &::before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        margin: -1px 14px 0 0;
        position: absolute;
        top: 1px;
        left: 0;
        border: 2px solid;
        border-color: $neutral-400;
        background-color: transparent;
        border-radius: 50%;
      }

      &:hover {
        &::before {
          border-color: $violet-300 !important;
        }
      }
    }

    &_input {
      display: none;

      &:checked + .Radio___small_label::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $violet-300;
      }

      &:checked + .Radio___small_label::before {
        border-color: $violet-300;
      }
    }
  }

  &___large {
    @include flex-center;

    &___disabled {
      opacity: 0.5;
    }

    &_label {
      @include ease(0.2s);
      @include flex-center;
      flex-direction: column;
      @include heading(1rem, 1.25rem);
      margin: 8px 0 0 0;
      width: 170px;
      height: 182px;
      border-radius: 16px;
      border: 1px solid;
      border-color: $neutral-200;
      color: $neutral-400;

      &:hover {
        color: $violet-300 !important;
      }
    }

    &_icon {
      font-size: 6rem;
      color: inherit;
    }

    &_input {
      display: none;

      &:checked + .Radio___large_label {
        background-color: transparentize($violet-300, 0.9);
        color: $violet-300;
        border: 1px solid $violet-300;
      }
    }
  }
}
