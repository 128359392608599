@import '../../../scss/variables';

.CreateReviewModal {
  &_withMarginBottom {
    margin-bottom: 20px !important;
  }

  &_name {
    margin-bottom: 30px;
    margin-top: 5px;
  }

  &_checkbox {
    border: 1px solid $border-300;
    margin-bottom: 10px;
    padding: 17px;
    

    &___red {
      > label {
        color: $red-300;

        &:hover {
          &::before {
            border-color: $red-300 !important;
          }
        }
      }

      > input:checked + label::before {
        background-color: $red-300 !important;
        border-color: $red-300 !important;
      }

      > input:focus + label::before {
        outline: $red-300 auto 5px !important;
      }
    }
  }

  &_warning {
    margin-bottom: 10px;
    margin-top: 5px;
  }

  &_notes {
    margin-top: 10px;
  }

  &_photos {
    margin-bottom: 20px;

    &_addPhotoBox {
      align-items: center;
      border: 1px solid $border-300;
      display: flex;
      margin-top: 10px;
      padding: 13px;
    }
  
    &_addPhoto {
      background: $violet-400;
      margin-right: 10px;
    }

    &_error {
      margin-top: 8px;
    }

    &_grid {
      display: grid;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      margin-top: 20px;
    }

    &_photoWrapper {
      position: relative;

      &_photo {
        width: 100%;
      }

      &_remove {
        background: $red-400;
        position: absolute;
        right: 0;
        top: 0;
        width: max-content;
      }
    }
  }
  
}