@import '../../../scss/variables';
@import '../../../scss/mixins';

.DeleteUser {
  max-width: 600px;
  text-align: center;
  width: 100%;

  &_header {
    padding: 20px;

    &_logo {
      width: 200px;
    }

    &_headingTextWrapper {
      // border-bottom: 1px solid $neutral-200;
      margin: 20px 0;
      text-align: center;

      &_headingText {
        background-color: $neutral-0;
        bottom: -8px;
        padding: 0 10px;
        position: relative;
        font-size: 1.500rem;

      }
      &_headingText2 {
        background-color: $neutral-0;
        bottom: -8px;
        padding: 0 10px;
        position: relative;
        font-weight: 700;
        font-size: 1.500rem;
      }
    }

  }

  &_content {
    padding: 0 20px 20px;

    &_input {
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }

      &_errorMessage {
        margin-top: 8px;
        text-align: left;
      }
    }

    // &_forgotPassword {
    //   color: $violet-400;
    //   display: block;
    //   font-weight: 700;
    //   margin-top: 20px;
    //   text-align: left;
    //   text-decoration: none;
    //   width: max-content;

    //   &:focus {
    //     color: $violet-200;
    //   }
    // }

    &_buttonGroup {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-top: 40px;

      &_buttonText {
        @include flex-center;
      }

      &_spinner {
        margin-left: 12px;
        width: auto;
      }
    }
  }
}