@import '../../../scss/mixins';
@import '../../../scss/variables';

.AddToProjectModel {
  &_withMarginBottom {
    margin-bottom: 20px;
  }

  &_label {
    margin-bottom: 8px;
  }

  &_tutorialLink {
    bottom: 35px;
    color: $blue-400;
    font-weight: 700;
    left: 20px;
    position: absolute;

    @include breakpoint(sm) {
      display: block;
      position: static;
      text-align: center;
    }
  }
}