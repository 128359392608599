@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.Subscription {
  &_title {
    margin-bottom: 40px;
  }

  &_alert {
    background: $blue-400;
    margin-bottom: 40px;
    padding: 15px;

    &_link {
      color: $yellow-100;
      text-decoration: underline;
    }
  }

  &_withMarginBottom {
    margin-bottom: 30px;
  }

  &_label {
    margin-bottom: 8px;
  }

  &_changePlanLink {
    color: $blue-400;
    float: right;
    font-weight: 700;
    margin-left: 20px;
  }

  &_cancelSubscriptionButton {
    display: flex;
    margin-left: auto;
    margin-top: 80px;
  }
}