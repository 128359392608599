@import '../../../scss/variables';
@import '../../../scss/mixins';

.Dashboard {
  &_cards {
    margin-top: 20px;

    &_card {
      padding: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
      &_grid {
        grid-template-columns: 1fr 0.2fr !important;
      }
      &_rightColumn {
        text-align: right;
      }

      &_icon {
        font-size: 3rem !important;

        &___green {
          color: $green-400 !important;
        }

        &___blue {
          color: $blue-400 !important;
        }

        &___violet {
          color: $violet-400 !important;
        }

        &___yellow {
          color: $yellow-400 !important;
        }

        &___red {
          color: $red-100 !important;
        }
        &___gray {
          color: $neutral-300 !important;
        }
      }

      @include breakpoint(xs) {
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      
      @include breakpoint(md) {
        padding: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  
  }
  &_fontBold {
    font-weight: 400;
    padding-right: 5px;
  }

  &_withMargin {
    margin-top: 60px !important;
    align-items: center;
  }
  &_graphMargin{
    margin-bottom: 70px;
    @include breakpoint(xs) {
      margin-bottom: 0px;
    }

  }
  &_graph {
    margin-top: 20px;
    padding: 40px;
    width: 70%;

    @include breakpoint(lg) {
      width: 100%;
    }

    @include breakpoint(xs) {
      padding: 20px;
    }
  }
  &_selectItem{
    background: $neutral-0;
  }

  &_firstRow {
    background: $neutral-0;
    box-shadow: $shadow-normal;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;
  }

  &_displayGrid {
    display: flex;
    justify-content: space-between;

    &_item{
      display:flex;
      align-items: center;
      
    }
    @include breakpoint(xs) {
      flex-direction: column;
      text-align: start;
    }
    @include breakpoint(md) {
      flex-direction: column;
      text-align: start;
    }
  }
  
  &_barGraph {
    height:500px;
    width: 100%;
    padding-top: 20px;
    margin: 0 auto;

    @include breakpoint(xs) {
      padding: 10px 5px;
      height: 200px; 
      width: 100%;
    }
    @include breakpoint(md) {
      padding: 10px 5px;
      height: 400px; 
      width: 100%;
    }
  }

  &_pieGraph {
    // margin-top: 10px;
    margin: auto;
    padding: 25px 60px 60px 60px;
    width: 70%;
    // width: 500px; /* Fixed width */
    // height: 600px;
    // canvas {
    //   position: relative;
    //   // Your styles for the canvas element go here
    // }

    @include breakpoint(xs) {
      margin: auto;
      padding: 5px; 
      width: 90%;
    }
    @include breakpoint(md) {
      margin: auto;
      padding: 2px 6px 6px 6px;
      width: 100%;
    }
  }
  &_legendLabel {
    display: flex;
    align-items: center;
    justify-content:space-between;
  }
  &_chart-container {
    position: relative;
    width: 300px;
    height: 300px;
  }
  
  &_legendcontainer {
    position: absolute;
    top: 0;
    right: -100px; /* Adjust the distance to move the legend outside the chart */
    display: flex;
    flex-direction: column;
  }
  
  &_legenditem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  &_legendcolor {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  // &_projectPieGraph {
  //   margin: auto;
  //   padding: 5px 100px 100px 100px;
  //   width: 70%;

  //   @include breakpoint(xs) {
  //     margin: auto;
  //     padding: 0px 2px 2px 2px;
  //     width: 100%;
  //   }
  //   @include breakpoint(md) {
  //     // margin-top: 10px;
  //     margin: auto;
  //     padding: 0px 2px 2px 2px;
  //     width: 100%;
  //   }
  // }
  

}