.MonthlyView {
  display: contents;

  &_fourRows {
    grid-row-start: span 4;
  }

  &_fiveRows {
    grid-row-start: span 5;
  }

  &_sixRows {
    grid-row-start: span 6;
  }
  
  &_overallTotalHours {
    grid-column: 12;
  }
}