@import '../../../../../scss/mixins';

.PreloaderLarge {
  @include ease(0.4s);
  height: 470px;
  margin-top: 20px;
  overflow: hidden;
  width: 70%;

  &_shine {
    background-size: 800px 470px;
    height: 470px;
  }
}