@import '../../../scss/variables';
@import '../../../scss/mixins';

.ViewVehicle {
  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &_reportButtons {
    align-items: center;
    display: flex;

    &_button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &_text {
        @include breakpoint(md) {
          display: none;
        }
      }

      i {
        @include breakpoint(md) {
          margin: 0;
        }
      }
    }
  }

  &_secondRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 93px;

    @include breakpoint(sm) {
      margin-top: 143px;
    }

    &_searchInput {
      margin-left: auto;
      width: 250px;
    }
  }

  &_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.7fr;
    margin-top: 24px;

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    > div:nth-child(1) {
      @include breakpoint(sm) {
        background-color: $violet-400;
        grid-row: 1;
        grid-column: 2;
      }
    }

    > div:nth-child(2) {
      @include breakpoint(sm) {
        grid-row: 1 / 7;
        grid-column: 1;
      }
    }

    > div:nth-child(3) {
      @include breakpoint(sm) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    > div:nth-child(4) {
      @include breakpoint(sm) {
        grid-row: 3;
        grid-column: 2;
      }
    }

    > div:nth-child(5) {
      @include breakpoint(sm) {
        grid-row: 4;
        grid-column: 2;
      }
    }

    > div:nth-child(6) {
      @include breakpoint(sm) {
        grid-row: 5;
        grid-column: 2;
      }
    }

    > div:nth-child(7) {
      @include breakpoint(sm) {
        grid-row: 6;
        grid-column: 2;
      }
    }

    > div:nth-child(8) {
      @include breakpoint(sm) {
        grid-row: 1 / 7;
        grid-column: 3;
      }
    }

    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }

      &_action {
        background-color: $blue-400;
      }
    }

    &_icon {
      font-size: 1.25rem;
      font-weight: 700;

      &___green {
        color: $green-400;
      }

      &___red {
        color: $red-400;
      }
    }

    &_viewButton {
      color: $green-400;
    }
  }

  &_withMargin {
    margin-top: 50px;
  }

  &_noReviews {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }
  }

  &_keyWrapper {
    display: contents;
  }
}