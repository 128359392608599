@import '../../../scss/mixins';
@import '../../../scss/variables';

.Preloader {
  @include ease(0.4s);
  overflow: hidden;

  &___Daily {
    max-width: 250px;

    @include breakpoint(xs) {
      max-width: 100%;
    }
  }

  &_header, &_body {
    display: grid;

    &___Daily {
      grid-template-columns: 0.4fr 0.8fr;

      @include breakpoint(sm) {
        grid-template-columns: 0.3fr 1.4fr;
      }
    }

    &___Weekly {
      grid-template-columns: 0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;

      @include breakpoint(sm) {
        grid-template-columns: 0.3fr 1.4fr;
      }
    }

    &___Fortnightly {
      grid-template-columns: 0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;

      @include breakpoint(sm) {
        grid-template-columns: 0.3fr 1.4fr;
      }
    }

    &___Monthly {
      grid-template-columns: 0.4fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.8fr;

      @include breakpoint(sm) {
        grid-template-columns: 0.3fr 1.4fr;
      }
    }
  }

  &_header {
    &_shine {
      height: 54px;

      @include breakpoint(sm) {
        display: none;
      }
  
      &:nth-child(1) {
        @include breakpoint(sm) {
          display: block;
        }
      }
      
      &:nth-child(2) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(3) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(4) {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }

  &_separator {
    border-color: $neutral-0;
  }

  &_body {
    &_shine {
      height: 94px;

      @include breakpoint(sm) {
        display: none;
      }
  
      &:nth-child(1) {
        @include breakpoint(sm) {
          display: block;
        }
      }
      
      &:nth-child(2) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(3) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(4) {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }
}