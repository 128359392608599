@import '../../../scss/mixins';

.Preloader {
  @include ease(0.4s);
  height: 100px;
  margin-top: 10px;
  overflow: hidden;

  &_shine {
    height: 100px !important;
  }
}