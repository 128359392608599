@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.Preloader {
  @include ease(0.4s);
  margin-top: 30px;
  overflow: hidden;

  &_header, &_body {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &_header {
    &_shine {
      height: 54px;

      @include breakpoint(sm) {
        height: 34px;
      }
    }
  }

  &_separator {
    border-color: $neutral-0;
  }

  &_body {
    &_shine {
      height: 94px;

      @include breakpoint(sm) {
        height: 43px;
      }
    }
  }
}