@import '../../scss/mixins';
@import '../../scss/variables';

@mixin dropdown($display) {
  display: $display;
  flex-direction: column;
  position: absolute !important;
  top: 46px;
  right: 9px;
  box-shadow: $shadow-normal;
  width: 150px;
  z-index: 1;
  border: none;

  @content;
}

.Navbar {
  align-items: center;
  background: $violet-400;
  box-shadow: $shadow-raised;
  color: $neutral-0;
  display: flex;
  height: 65px;
  justify-content: space-between;
  left: 0;
  padding: 0 24px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;

  @include breakpoint(xs) {
    padding: 0 8px;
  }

  &_section {
    width: 33.333%;
  }

  &_logo {
    align-items: center;
    display: flex;
    justify-content: center;

    &_icon {
      margin-right: 10px;
      width: 25px;
    }

    &_text {
      font-weight: 400 !important;
    }
  }

  &_userType {
    background: $neutral-0;
    border-radius: 100px;
    margin-left: 16px;
    padding: 4px 8px;

    @include breakpoint(xs) {
      margin-left: 4px;
    }

    &_text {
      color: $violet-400;
    }
  }

  &_navUser {
    display: flex;
    justify-content: flex-end;
    position: relative;

    &_companyLogoWrapper {
      align-items: center;
      background-color: #F4F2FB;
      border-radius: 16px;
      display: flex;
      height: 34px;
      justify-content: center;
      margin-right: 24px;
      padding: 4px 16px;
      width: 90px;
      @media only screen and (min-width: 600px) and (max-width: 801px) {
        margin-right: 0px;
        }
      @include breakpoint(sm) {
        display: none;
      }
    }

    &_companyLogo {
      margin: 0 auto;
      object-fit: contain;
      width: 90px;
      height: 34px;
    }

    &_caret {
      color: $neutral-0 !important;
      margin: 0 0 0 4px;
    }

    &_name {
      @include heading(1rem, 1.67);
      
      color: $neutral-0;

      @include breakpoint(sm) {
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
      }

      @include breakpoint(xs) {
        max-width: 50px;
      }
    }

    &_button {
      @include flex-center;
      @include ease(0.2s);

      background: transparent;
      border: none;
      color: $neutral-0;
      cursor: pointer;
      outline: none;
    }

    &_dropdown {
      @include dropdown(none);

      &___toggled {
        @include dropdown(flex);
      }

      &_link {
        @include heading(1rem, 1.67);
        @include ease(0.3s);

        text-decoration: none;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        color: $neutral-700;

        &:hover {
          background-color: $neutral-50;
        }

        &:focus,
        &:active {
          background-color: $neutral-100;
        }

        &:first-child {
          border-radius: 4px 4px 0 0;
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
        }

        &_icon {
          margin-right: 8px;
          color: $neutral-400;
        }
      }
    }
  }
}