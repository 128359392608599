@import '../../../../scss/mixins';

.Vehicles {
  &_title {
    margin-bottom: 40px;
  }

  &_input {
    margin-bottom: 20px;
  }

  &_buttonGroup {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-top: 40px;
    width: max-content;

    &_buttonText {
      @include flex-center;
    }

    &_spinner {
      margin-left: 12px;
      width: auto;
    }
  }
}