@import '../../scss/mixins';
@import '../../scss/variables';

.EmployeeContainer {
  padding: 95px 30px 30px;

  @include breakpoint(sm) {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.helpButton {
  align-items: center;
  background-color: $blue-400;
  bottom: 20px;
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 50px;
  z-index: 2;

  &:hover, &:focus {
    background-color: $blue-200;
  }

  &_icon {
    color: $neutral-0;
    font-size: 24px;
  }
}