@import '../../scss/variables';
@import '../../scss/mixins';

.RosterGrid {
  border-top: 1px solid $neutral-50;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @include breakpoint(sm) {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

  &_column {
    background-color: $neutral-0;
    border: .5px solid $border-200;
    display: flex;
    flex-direction: column;
    padding: 4px;

    &_data {
      height: 500px;
      overflow-y: auto;
      
      @include breakpoint(sm) {
        height: auto;
        min-height: 120px;
      }
    }

    &_even {
      background-color: $violet-25;
    }

    &:nth-child(8) {
      @include breakpoint(sm) {
        grid-row: 2;
      }
    }

    &:nth-child(9) {
      @include breakpoint(sm) {
        grid-row: 4;
      }
    }

    &:nth-child(10) {
      @include breakpoint(sm) {
        grid-row: 6;
      }
    }

    &:nth-child(11) {
      @include breakpoint(sm) {
        grid-row: 8;
      }
    }

    &:nth-child(12) {
      @include breakpoint(sm) {
        grid-row: 10;
      }
    }

    &:nth-child(13) {
      @include breakpoint(sm) {
        grid-row: 12;
      }
    }

    &:nth-child(14) {
      @include breakpoint(sm) {
        grid-row: 14;
      }
    }
  }

  &_header {
    align-items: center;
    background-color: $violet-400;
    color: $neutral-0;
    padding: 18px 8px;
  }
}