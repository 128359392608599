@import '../../../../scss/variables';

.Costs {
  &_cost {
    align-items: center;
    border: 1px solid $neutral-300;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1.5fr 0.3fr 0.2fr;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 12px 16px;

    &_price {
      font-weight: 700;
    }

    &_delete {
      color: $red-400;
      margin-left: auto;
    }
  }

  &_addCost {
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr;
    grid-column-gap: 12px;

    &_error {
      margin-top: 8px;
    }

    &_button {
      max-height: 51px;
    }
  }
}