@import '../../scss/mixins';

.LoginSignupContainer {
  @include flex-center;
  height: 100%;

  @include breakpoint(xs) {
    height: auto;
    margin: 24px;
  }
}