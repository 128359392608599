@import '../../../scss/mixins';
@import '../../../scss/variables';

.CreateTimesheet {
  &_card {
    margin-top: 20px;
    max-width: 450px;
    padding: 20px;
  }

  &_withMargin {
    margin-top: 20px;
  }

  &_withTooltip {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;

    &_tooltipLink {
      cursor: pointer;
      margin-left: auto;
    }

    &_tooltipIcon {
      color: $violet-400;
      font-size: 20px !important;
    }

    &_tooltip {
      background: $neutral-0 !important;
      box-shadow: $shadow-raised;
      color: $neutral-700 !important;
      font-size: 14px !important;
      max-width: 400px;
      padding: 15px !important;
      text-align: left;

      &::after {
        border-top-color: $neutral-0 !important;
      }
    }
  }

  &_buttonGroup {
    margin-top: 40px;

    &_button {
      width: 100%;
    }

    &_buttonText {
      @include flex-center;
    }

    &_spinner {
      margin-left: 12px;
      width: auto !important;
    }
  }

  &_summary {
    &_row {
      padding: 12px;

      &:first-child {
        margin-top: 20px;
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &_group {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    &_label {
      margin-bottom: 6px;
    }
  }
}