@import '../../../../scss/mixins';

.EmployerDetails {
  &_title {
    margin-bottom: 40px;
  }

  &_input {
    margin-top: 20px;
    width: 100%;
  }

  &_leaveCompanyButton {
    display: flex;
    margin-left: auto;
    margin-top: 80px;
  }
}