// Neutrals
$neutral-900: #1f2730;
$neutral-800: #28313b;
$neutral-700: #2d3845;
$neutral-600: #3f4e5f;
$neutral-500: #626b77;
$neutral-400: #88919b;
$neutral-300: #b0b9bf;
$neutral-200: #d1dadf;
$neutral-100: #e2eaee;
$neutral-50: #f6f9fb;
$neutral-0: #ffffff;

// Violets
$violet-400: #967ADC;
$violet-300: #A992E2;
$violet-200: #AC92EC;
$violet-100: #BCAAE9;
$violet-75: #EAE1FF;
$violet-25: #FAF8FF;

// Blues
$blue-400: #3BAFDA;
$blue-300: #55B9DF;
$blue-200: #4FC1E9;
$blue-100: #6EC4E4;
$blue-75: #DBF2FF;
$blue-50: #C4EBFF;
$blue-25: #E4F6FF;
$blue-0: #F0FAFF;

// Reds
$red-400: #DA4453;
$red-300: #DF5E6A;
$red-200: #ED5565;
$red-100: #E47782;

// Greens
$green-400: #37BC9B;
$green-300: #48C9A9;
$green-200: #48CFAD;
$green-100: #60D0B4;

// Yellows
$yellow-400: #F6BB42;
$yellow-300: #F7C55F;
$yellow-200: #FFCE54;
$yellow-100: #F9D07C;
$yellow-75: #FFFFEB;

// Pinks
$pink-75: #FFE2F7;

// Borders
$border-300: #CFCFCF;
$border-200: #F3F3F3;
$border-100: #F5F5F5;

// Dark Gray
$darkgrey-400: #88919b;

$shadow-normal: rgba(136, 153, 166, 0.2) 0 0 15px,
  rgba(136, 153, 166, 0.15) 0 0 3px 1px;
$shadow-raised: rgba(136, 153, 166, 0.25) 0 0 15px,
  rgba(136, 153, 166, 0.15) 0 0 3px 1px;