@import '../../../../../scss/mixins';

.PreloaderSmall {
  @include ease(0.4s);
  height: 100px;
  overflow: hidden;

  &_shine {
    height: 100px !important;
  }
}