@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.RosterSidebar {
  @include breakpoint(sm) {
    background: $neutral-0;
    order: -1;
    margin-bottom: 20px;
    padding: 20px;
  }

  &_header {
    align-items: center;
    border-bottom: 1px solid $border-300;
    display: flex;
    height: 54px;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;

    &_button {
      padding: 10px;
    }
  }

  &_list {
    max-height: 400px;
    overflow-y: auto;
    
    &_button {
      background-color: $yellow-75 !important;
      border-radius: 8px;
      color: $neutral-700;
      display: block;
      margin-top: 10px;
      padding: 10px;
  
      &___blue {
        background-color: $blue-75 !important;
      }
    
      &___pink {
        background-color: $pink-75 !important;
      }
    
      &___violet {
        background-color: $violet-75 !important;
      }

      &_text {
        @include flex-center;
      }
  
      &_spinner {
        margin-left: 12px;
        width: auto !important;
      }
    }
  }
}