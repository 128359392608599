@import '../../../scss/mixins';
@import '../../../scss/variables';

.AddEmployee {
  &_card {
    margin-top: 20px;
    padding: 20px;

    &_title {
      margin-bottom: 40px;
    }

    &_smallNote {
      background-color: $blue-400;
      border-radius: 4px;
      color: $neutral-0;
      margin-bottom: 40px;
      margin-top: 15px;
      padding: 10px;
    }
  }

  &_withMarginBottom {
    margin-bottom: 20px;
  }

  &_buttonGroup {
    margin-top: 40px;

    &_button {
      width: 100%;
    }

    &_buttonText {
      @include flex-center;
    }

    &_spinner {
      margin-left: 12px;
      width: auto !important;
    }
  }

  &_alert {
    background: $red-400;
    margin-top: 20px;
    max-width: 500px;
    padding: 15px;

    &_text {
      line-height: 1.5em;
      text-align: justify;
    }

    &_link {
      color: $yellow-100;
      text-decoration: underline;
    }
  }
}