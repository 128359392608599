@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.Preloader {
  @include ease(0.4s);
  margin-top: 93px;
  overflow: hidden;

  @include breakpoint(sm) {
    margin-top: 143px;
  }

  &_header, &_body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.4fr;

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 33.33%);
    }
  }

  &_header {
    &_shine {
      height: 54px;

      @include breakpoint(sm) {
        display: none;
      }
  
      &:nth-child(1) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(2) {
        @include breakpoint(sm) {
          display: block;
        }
      }

      &:nth-child(3) {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }

  &_separator {
    border-color: $neutral-0;
  }

  &_body {
    &_shine {
      height: 94px;

      @include breakpoint(sm) {
        display: none;
      }
  
      &:nth-child(1) {
        @include breakpoint(sm) {
          display: block;
        }
      }
  
      &:nth-child(2) {
        @include breakpoint(sm) {
          display: block;
        }
      }

      &:nth-child(3) {
        @include breakpoint(sm) {
          display: block;
        }
      }
    }
  }
}