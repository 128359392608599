@import '../../../../scss/mixins';

.Notifications {
  &_title {
    margin-bottom: 40px;
  }

  &_inputGroup {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    &_label {
      margin-right: 5px;
      width: 100px;
    }
  }

  &_buttonGroup {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-top: 40px;
    width: max-content;

    &_buttonText {
      @include flex-center;
    }

    &_spinner {
      margin-left: 12px;
      width: auto;
    }
  }
}