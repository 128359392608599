@import '../../../scss/variables';
@import '../../../scss/mixins';

.ViewProject {
  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }
  &_cards {
    margin-top: 20px;

    &_card {
      padding: 20px;
      height: 130px;
      // width: 80em;
      &_grid {
        grid-template-columns: 0.1fr 0.7fr !important;
      }

      &_rightColumn {
        text-align: right;
      }
      &_nameText {
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 100%;
        margin-left: 7%; 
        font-size: 25px;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
          margin-left: 10%; 
          font-size: 18px;
        }
      }
      &_leftColumn {
        display: inline-flex;
        width: 100%;
        margin-top: 2%;
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 120%;
          margin-top: 2%; 
          margin-left: 7%;
          display: flex;
         flex-direction: column;
        }
      }
      &_leftColumnStart {
        display: inline-flex;
        width: 25%;
        align-items: center;
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
        }
        @media only screen and (min-width: 480px) and (max-width: 821px) {
          width: 100%;
          margin-left: 7%;
        }
        @media only screen and (min-width: 821px) and (max-width: 1024px) {
          width: 100%;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 100%;
        }
      }
      &_leftColumnEnd {
        display: inline-flex;
        width: 35%;
        align-items: center;
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
        }
        @media only screen and (min-width: 480px) and (max-width: 821px) {
          width: 100%;
        }
        @media only screen and (min-width: 821px) and (max-width: 1024px) {
          width: 100%;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 100%;
        }
      }
      &_userIcon {
        text-align: right;
        border-radius: 50%;
        width: 80%;
        height: 89px;
        background-color: #ffdada;
        @media only screen and (max-width: 280px) {
          width: 100%;
          border-radius: 50%;
          height: 89px;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 150%;
          border-radius: 50%;
          height: 89px;
        }
        @media only screen and (min-width: 480px) and (max-width: 820px) {
          width: 130%;
          border-radius: 50%;
          height: 89px;
        }
        @media only screen and (min-width: 820px) and (max-width: 1024px) {
          width: 100%;
          border-radius: 50%;
          height: 89px;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 120%;
          border-radius: 50%;
          height: 89px;
        }
      }
      &_icon {
        font-size: 1.5rem !important;
        margin: 2%;

        &___green {
          color: $green-400 !important;
        }
        &___blue {
          color: $blue-400 !important;
        }

        &___violet {
          color: $violet-400 !important;
        }

        &___yellow {
          color: $yellow-400 !important;
        }
      }
      &_iconUser {
        font-size: 4rem !important;
        border: 1px;
        margin: 17%;
        fill: blueviolet !important;
        &___red {
          color: $red-400 !important;
          // fill: 1 !important;
        }
      }
    }
    &_card1 {
      padding: 20px;
      height: 130px;
      &_grid {
        grid-template-columns: 1fr 0.2fr !important;
      }
    
      &_rightColumn {
        text-align: right;
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          position: absolute;
          right: 4%;
        }
        @media only screen and (min-width: 480px) and (max-width: 820px) {
          position: absolute;
          right: 4%;
        }
      }
      &_leftColumn{
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 50%;
        }
      }
      &_icon {
        font-size: 5rem !important;

        &___green {
          color: $green-400 !important;
        }
        &___red {
          color: $red-400 !important;
        }
        &___lightGray {
          color: $darkgrey-400 !important;
        }
        
        &___blue {
          color: $blue-400 !important;
        }
        &___nuetral {
          color: $neutral-0 !important;
        }
        &___violet {
          color: $violet-400 !important;
        }

        &___yellow {
          color: $yellow-400 !important;
        }
      }
    }
  }
  &_grid {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 73px;

    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
      margin-top: 123px;
    }

    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }

      &_action {
        background-color: $blue-400;
      }
    }

    &_viewButton {
      color: $green-400;
    }

    &_editButton {
      color: $blue-400;
      margin-left: 5px;
    }

    &_deleteButton {
      color: $red-400;
      margin-left: 5px;
    }

    > div:nth-child(1) {
      @include breakpoint(sm) {
        background-color: $violet-400;
        grid-row: 1;
        grid-column: 2;
      }
    }

    > div:nth-child(2) {
      @include breakpoint(sm) {
        grid-row: 1 / 8;
        grid-column: 1;
      }
    }

    > div:nth-child(3) {
      @include breakpoint(sm) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    > div:nth-child(4) {
      @include breakpoint(sm) {
        grid-row: 3;
        grid-column: 2;
      }
    }

    > div:nth-child(5) {
      @include breakpoint(sm) {
        grid-row: 4;
        grid-column: 2;
      }
    }

    > div:nth-child(6) {
      @include breakpoint(sm) {
        grid-row: 5;
        grid-column: 2;
      }
    }

    > div:nth-child(7) {
      @include breakpoint(sm) {
        grid-row: 6;
        grid-column: 2;
      }
    }

    > div:nth-child(8) {
      @include breakpoint(sm) {
        grid-row: 7;
        grid-column: 2;
      }
    }

    > div:nth-child(9) {
      @include breakpoint(sm) {
        grid-row: 8;
        grid-column: 2;
      }
    }

    > div:nth-child(10) {
      @include breakpoint(sm) {
        grid-row: 8 / 15;
        grid-column: 1;
      }
    }

    > div:nth-child(11) {
      @include breakpoint(sm) {
        grid-row: 9;
        grid-column: 2;
      }
    }

    > div:nth-child(12) {
      @include breakpoint(sm) {
        grid-row: 10;
        grid-column: 2;
      }
    }

    > div:nth-child(13) {
      @include breakpoint(sm) {
        grid-row: 11;
        grid-column: 2;
      }
    }

    > div:nth-child(14) {
      @include breakpoint(sm) {
        grid-row: 12;
        grid-column: 2;
      }
    }

    > div:nth-child(15) {
      @include breakpoint(sm) {
        grid-row: 13;
        grid-column: 2;
      }
    }
  }

  &_reportButtons {
    align-items: center;
    display: flex;

    &_button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &_text {
        @include breakpoint(md) {
          display: none;
        }
      }

      i {
        @include breakpoint(md) {
          margin: 0;
        }
      }
    }
  }

  &_withMarginTop {
    margin-top: 20px;
  }

  &_withMarginBottom {
    margin-bottom: 20px;
  }

  &_thirdRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 50px;

    @include breakpoint(lg) {
      flex-direction: column;
    }

    > a {
      @include breakpoint(lg) {
        margin: 0 auto;
        width: max-content;
      }
    }
  }

  &_navigationContainer {
    align-items: center;
    display: flex;

    @include breakpoint(lg) {
      flex-direction: column;
      margin-bottom: 24px;
    }

    &_navigation {
      display: flex;
      margin-left: 24px;

      @include breakpoint(lg) {
        margin-top: 24px;
      }

      @include breakpoint(xs) {
        margin-left: 0;
      }

      &_item {
        margin-right: 16px;
        width: auto !important;

        @include breakpoint(xs) {
          margin-right: 8px;
        }

        &:last-child {
          margin-right: 0;
        }

        &_buttonText {
          @include breakpoint(xs) {
            display: none;
          }
        }

        i {
          @include breakpoint(xs) {
            margin: 0;
          }
        }
      }
    }
  }

  &_withSummaryMargin {
      margin-top: 5rem;
      @media only screen and (min-width: 280px) and (max-width: 480px) {
        margin-top: 120px;
      }
      @media only screen and (min-width: 480px) and (max-width: 600px) {
        margin-top: 120px;
      }
    }
  
  &_noEmployees {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }

    &_withMargin {
      margin-top: 10px;
    }
    &_link {
      color: $blue-400;
    }
  }
}