@import '../../../scss/variables';
@import '../../../scss/mixins';

.ViewClient {
  // overflow-x: hidden;

  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;
    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }
  &_cards {
    margin-top: 20px;
    &_card {
      padding: 20px;
      height: 130px;
      @media only screen and (min-width: 280px) and (max-width: 600px) {
        height: 140px;
      }
      &_grid {
        grid-template-columns: 0.1fr 0.7fr !important;
      }
      &_nameText {
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 100%;
        margin-left: 7%; 
        font-size: 25px;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
          margin-left: 7%; 
          font-size: 20px;
        }
        @media only screen and (min-width: 821px) and (max-width: 1370px) {
          width: 100%;
        margin-left: 7%; 
        font-size: 25px;
        }
      }
      &_rightColumn {
        text-align: right;
      }
      &_leftColumn1 {
        @media only screen and (min-width: 600px) and (max-width: 821px) {
        margin-left: 5px; 
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
        margin-left: 7%;
        }
      }
      &_leftColumn {
        display: inline-flex;
        width: 100%;
        margin-top: 2%;
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 230%;
        margin-top: 2%; 
        margin-left: 7%;
        display: flex;
        flex-direction: row;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 120%;
        margin-top: 2%; 
        margin-left: 7%;
        display: flex;
       flex-direction: column;
        }
        @media only screen and (min-width: 480px) and (max-width: 600px) {
          width: 120%;
        margin-top: 2%; 
        margin-left: 7%;
        display: flex;
       flex-direction: column;
        }
        @media only screen and (min-width: 821px) and (max-width: 1024px) {
          width: 120%;
        margin-top: 2%; 
        margin-left: 7%;
        display: flex;
        flex-direction: row;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1370px) {
          width: 120%;
        margin-top: 2%; 
        margin-left: 7%;
        display: flex;
        flex-direction: row;
        }
      }
      &_leftColumnEmail {
        display: inline-flex;
        width: 40%;
        align-items: center;
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 40%;
          margin: 1%;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
        }
        @media only screen and (min-width: 480px) and (max-width: 600px) {
          width: 100%;
        }
        @media only screen and (min-width: 821px) and (max-width: 1024px) {
          width: 100%;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 100%;
        }
      }
      &_leftColumnPhone {
        display: inline-flex;
        width: 30%;
        align-items: center;
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 30%;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
        }
        @media only screen and (min-width: 480px) and (max-width: 600px) {
          width: 100%;
        }
        @media only screen and (min-width: 821px) and (max-width: 1024px) {
          width: 100%;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 100%;
        }
      }
      &_leftColumnLocation {
        display: inline-flex;
        width: 100%;
        align-items: center;
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 100%;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 100%;
        }
        @media only screen and (min-width: 480px) and (max-width: 600px) {
          width: 100%;
        }
        @media only screen and (min-width: 821px) and (max-width: 1024px) {
          width: 100%;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 100%;
        }
      }
      &_userIcon {
        text-align: right;
        border-radius: 50%;
        width: 65%;
        height: 91px;
        background-color: #f1ecfc;
        @media only screen and (min-width: 600px) and (max-width: 821px) {
          width: 149%;
          height: 91px;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 145%;
          height: 91px;
          margin: 10%;
        }
        @media only screen and (min-width: 480px) and (max-width: 820px) {
          width: 130%;
          border-radius: 50%;
          height: 89px;
        }
        @media only screen and (min-width: 820px) and (max-width: 1024px) {
          width: 145%;
          border-radius: 50%;
          height: 89px;
        }
        @media only screen and (min-width: 1024px) and (max-width: 1380px) {
          width: 110%;
          border-radius: 50%;
          height: 89px;
        }
      }
      &_icon {
        font-size: 1.5rem !important;
        margin: 2%;

        &___green {
          color: $green-400 !important;
        }
        &___blue {
          color: $blue-400 !important;
        }

        &___violet {
          color: $violet-400 !important;
        }

        &___yellow {
          color: $yellow-400 !important;
        }
      }
      &_iconUser {
        font-size: 4rem !important;
        border: 1px;
        margin: 17%;
        fill: blueviolet !important;
        &___violet {
          color: $violet-400 !important;
          // fill: 1 !important;
        }
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          width: 70%;
          height: 85px;
        }
      }
    }
    &_card1 {
      padding: 20px;
      height: 130px;
      @media only screen and (max-width: 280px) {
        // width: 20em !important;
      }
      @media only screen and (min-width: 280px) and (max-width: 480px) {
        // width: 27em !important;
      }
      @media only screen and (max-width: 600px) {
        // width: 40em;
      }
      &_grid {
        grid-template-columns: 0.1fr 0.7fr !important;
      }

      &_rightColumn {
        text-align: right;
        @media only screen and (min-width: 280px) and (max-width: 480px) {
          position: absolute;
          right: 4%;
        }
        @media only screen and (min-width: 480px) and (max-width: 820px) {
          position: absolute;
          right: 4%;
        }
      }

      &_icon {
        font-size: 5rem !important;
        @media only screen and (min-width: 767px) and (max-width: 1024px) {
          font-size: 3rem !important;
        }
        &___green {
          color: $green-400 !important;
        }
        
        &___blue {
          color: $blue-400 !important;
        }
        &___nuetral {
          color: $neutral-0 !important;
        }
        &___violet {
          color: $violet-400 !important;
        }

        &___yellow {
          color: $yellow-400 !important;
        }
      }
    }
  }
  &_secondRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    @include breakpoint(lg) {
      flex-direction: column;
    }

    @include breakpoint(sm) {
      margin-top: 143px;
    }
  }
  &_projectSearchDiv{
    display: flex;
    float: right;
    margin-top: -3rem;
    @media only screen and (min-width: 280px) and (max-width: 421px) {
      margin-top: 20px !important;
    }
  }

  &_inputSearchBox input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &_searchDesign {
    margin: 0;
  }
  &_search {
    display: flex;
  }
  &_searchButton {
    height: 50.5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    padding: 10% 5.5%;
    & i{
      font-size: 22px;
    }
  }
  &_clientGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 73px;
    
    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(sm) {
      &___notAnEmployee {
        margin-top: 135px;
      }
    }

    > div:nth-child(1) {
      @include breakpoint(sm) {
        grid-row: 1 / 6;
        grid-column: 1;
      }
    }

    > div:nth-child(2) {
      @include breakpoint(sm) {
        grid-row: 1;
        grid-column: 2;
      }
    }

    > div:nth-child(3) {
      @include breakpoint(sm) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    > div:nth-child(4) {
      @include breakpoint(sm) {
        grid-row: 3;
        grid-column: 2;
      }
    }

    > div:nth-child(5) {
      @include breakpoint(sm) {
        grid-row: 4;
        grid-column: 2;
      }
    }

    > div:nth-child(6) {
      @include breakpoint(sm) {
        grid-row: 5;
        grid-column: 2;
      }
    }

    > div:nth-child(7) {
      @include breakpoint(sm) {
        grid-row: 6 / 11;
        grid-column: 1;
      }
    }

    > div:nth-child(8) {
      @include breakpoint(sm) {
        grid-row: 6;
        grid-column: 2;
      }
    }

    > div:nth-child(9) {
      @include breakpoint(sm) {
        grid-row: 7;
        grid-column: 2;
      }
    }

    > div:nth-child(10) {
      @include breakpoint(sm) {
        grid-row: 8;
        grid-column: 2;
      }
    }

    > div:nth-child(11) {
      @include breakpoint(sm) {
        grid-row: 9;
        grid-column: 2;
      }
    }

    > div:nth-child(12) {
      @include breakpoint(sm) {
        grid-row: 10;
        grid-column: 2;
      }
    }
  }

  &_projectGrid {
    display: grid;
    grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 24px;
    @media only screen and (min-width: 280px) and (max-width: 421px) {
      margin-top: 90px !important;
    }
    @include breakpoint(sm) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    > div:nth-child(1) {
      @include breakpoint(sm) {
        background-color: $violet-400;
        grid-row: 1;
        grid-column: 2;
      }
    }

    > div:nth-child(2) {
      @include breakpoint(sm) {
        grid-row: 1 / 7;
        grid-column: 1;
      }
    }

    > div:nth-child(3) {
      @include breakpoint(sm) {
        grid-row: 2;
        grid-column: 2;
      }
    }

    > div:nth-child(4) {
      @include breakpoint(sm) {
        grid-row: 3;
        grid-column: 2;
      }
    }

    > div:nth-child(5) {
      @include breakpoint(sm) {
        grid-row: 4;
        grid-column: 2;
      }
    }

    > div:nth-child(6) {
      @include breakpoint(sm) {
        grid-row: 5;
        grid-column: 2;
      }
    }

    > div:nth-child(7) {
      @include breakpoint(sm) {
        grid-row: 6;
        grid-column: 2;
      }
    }

    > div:nth-child(8) {
      @include breakpoint(sm) {
        grid-row: 1 / 7;
        grid-column: 3;
      }
    }
  }

  &_grid {
    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }

      &_action {
        background-color: $blue-400;
      }
    }

    &_viewButton {
      color: $green-400;
    }

    &_editButton {
      color: $blue-400;
      margin-left: 5px;
    }

    &_deleteButton {
      color: $red-400;
      margin-left: 5px;
    }
  }

  &_withMargin {
    margin-top: 50px;
    @media only screen and (min-width: 280px) and (max-width: 767px) {
      margin-top: 60px;
    }
  }

  &_noProjects {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 60vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }

    &_withMargin {
      margin-top: 10px;
    }
  }

  &_keyWrapper {
    display: contents;
  }
}