@import '../../../scss/mixins';

.FortnightlyView {
  display: contents;

  &_twoRows {
    grid-row-start: span 2;
  }

  &_overallTotalHours {
    grid-column: 10;
  }
}