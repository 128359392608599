@import '../../../scss/variables';
@import '../../../scss/mixins';

.TabletView {
  &___passwordProtected {
    align-items: center;
    display: flex;
    height: calc(100vh - 125px);
    justify-content: center;
  }

  &_breadcrumbs {
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;
  }

  &_passwordCard {
    margin: 0 auto;
    padding: 20px;
    width: 500px;
  }

  &_headingText {
    color: $violet-400;
    margin-bottom: 20px;
    text-align: center;
  }

  &_withMarginBottom {
    margin-bottom: 10px;
  }

  &_buttonGroup {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 30px;

    &_buttonText {
      @include flex-center;
    }

    &_spinner {
      margin-left: 12px;
      width: auto;
    }
  }

  &_firstRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;

    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;
      word-break: break-word;

      @include breakpoint(sm) {
        padding: 8px;
      }

      &_createTimesheetIcon {
        color: $green-400;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  }

  &_keyWrapper {
    display: contents;
  }

  &_noEmployees {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }

    &_withMargin {
      margin-top: 10px;
    }

    &_link {
      color: $blue-400;
    }
  }
}