@import '../../../scss/variables';
@import '../../../scss/mixins';

.timePicker {
  width: 100%;
  height: 50px;
  .MuiFormLabel-root {
    color: $neutral-500;
    opacity: 0.8;
    font-size: 1rem;
    text-transform: capitalize;
    display: block;
    margin: 4px 0 0 0;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    line-height: 1.5;
    letter-spacing: normal;
    left: 12px;
    z-index: 1;
    pointer-events: none;
  }
  .MuiIconButton-root {
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    position: absolute;
    right: 0;
}
.MuiFormHelperText-root {
  display: none;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
  .MuiInputBase-root {
    color: $neutral-700;
    background-color: $neutral-0;
    border: 1px solid;
    border-color: $neutral-300;
    border-radius: 4px;
    font-size: 0.9rem;
    display: block;
    margin: 0 0 8px 0;
    font-family: 'Open Sans', sans-serif;
    white-space: nowrap;
    padding: 17px 12px;
    @include ease(0.2s);

    &:focus {
      border-color: $violet-300;
    }

    &:active {
      border-color: $violet-300;
    }
  }

  .MuiInputBase-input {
    padding: 0;
  }

  .MuiInputLabel-shrink {
    display: none;
  }

  .MuiInputLabel-formControl {
    transform: translate(0, 4px) scale(1);
  }

  .MuiFormControl-root {
    @include ease(0.2s);
    width: 100%;
  }

  .MuiInput-formControl {
    margin-bottom: 0;
  }

  label + .MuiInput-formControl {
    margin: 0;
  }

  .MuiInput-underline:before {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }

  .MuiInput-underline:after {
    border: none;
  }

  .MuiInput-underline.Mui-disabled:before {
    border: none;
  }

  .MuiInputBase-root.Mui-disabled {
    background-color: $neutral-100;
  }

  .MuiInputBase-input.Mui-disabled {
    color: $neutral-700;
  }
}


