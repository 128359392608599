@import '../../../scss/variables';
@import '../../../scss/mixins';

.Dashboard {
  &_cards {
    margin-top: 20px;

    &_card {
      padding: 20px;

      &_grid {
        grid-template-columns: 1fr 0.2fr !important;
      }

      &_rightColumn {
        text-align: right;
      }

      &_icon {
        font-size: 3.5rem !important;

        &___green {
          color: $green-400 !important;
        }

        &___blue {
          color: $blue-400 !important;
        }

        &___violet {
          color: $violet-400 !important;
        }

        &___yellow {
          color: $yellow-400 !important;
        }

        &___red {
          color: $red-400 !important;
        }
      }
    }
  }

  &_secondRow {
    margin-top: 50px;

    &_filters {
      column-gap: 20px;
      display: flex;
      justify-content: center;
    }
  }

  &_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 30px;

    @include breakpoint(sm) {
      grid-template-columns: repeat(4, 25%);
    }

    &_column {
      align-items: center;
      background-color: $neutral-0;
      border: .5px solid $border-200;
      display: flex;
      justify-content: center;
      padding: 30px 8px;
      text-align: center;
      word-break: break-word;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  
    &_header {
      background-color: $violet-400;
      color: $neutral-0;
      padding: 18px 8px;

      @include breakpoint(sm) {
        padding: 8px;
      }
    }
  }

  &_withMargin {
    margin-top: 40px !important;
  }

  &_noUsers {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    text-align: center;

    &_withMargin {
      margin-top: 10px;
    }
  }

  &_keyWrapper {
    display: contents;
  }
}