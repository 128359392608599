@import '../../../scss/variables';
@import '../../../scss/mixins';

.Subscription {
  &_alert {
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
    width: max-content;
  }

  &_alert {
    background: $red-400;
  }

  &_info {
    background: $blue-400;
  }

  &_grid {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    grid-column-gap: 40px;

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
  }

  &_card {
    padding: 20px;

    @include breakpoint(sm) {
      margin-bottom: 20px;
    }

    &_title {
      margin-bottom: 40px;
    }
  }

  &_timeIntervalButtons {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    &_button {
      background: none !important;
      border: 1px solid $violet-400;
      color: $violet-400;
      margin: 0 10px;

      &___active {
        background: $violet-400 !important;
        color: $neutral-0;
      }
    }
  }

  &_withMarginBottom {
    margin-bottom: 20px;
  }

  &_label {
    margin-bottom: 8px;
  }

  &_currentPaymentMethod {
    &_container {
      align-items: center;
      display: flex;
    }

    &_cardImage {
      margin-right: 10px;
      width: 40px;
    }
  }

  &_summaryRow {
    border: 1px solid $border-300;
    padding: 15px;
  }

  &_submitButton {
    margin-top: 40px;
    width: 100%;
  }

  &_keyWrapper {
    display: contents;
  }

  &_loader {
    align-items: center;
    background: #00000080;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;
  }
}