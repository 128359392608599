@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/animations';

@mixin circle($color) {
  fill: none;
  stroke: $color;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@mixin base($size) {
  width: $size;
  transform-origin: center;
  animation: rotate 2s linear infinite;
}

.Spinner {
  &_container {
    width: 100%;
    @include flex-center;
  }

  &___lg {
    @include base(3.75rem);
  }

  &___md {
    @include base(2.5rem);
  }

  &___sm {
    @include base(1.25rem);
  }

  &___xs {
    @include base(1rem);
  }

  &_circle {
    &___white {
      @include circle($neutral-0);
    }

    &___gray {
      @include circle($neutral-400);
    }

    &___violet {
      @include circle($violet-300);
    }
  }
}
