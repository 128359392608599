@import '../../scss/variables';

.GoogleMapMarker {
  position: relative;

  &_card {
    background-color: $neutral-0;
    border-radius: 4px;
    height: 250px;
    overflow-y: auto;
    padding: 10px 15px 10px 10px;
    position: absolute;
    right: 20px;
    top: 0;
    width: 200px;

    &_closeButton {
      color: $neutral-700;
      margin-bottom: 5px;
      margin-left: auto;
      padding: 0;
    }
  }

  &_pin {
    border-radius: 50% 50% 50% 0;
    border: 1px solid $neutral-700;
    cursor: pointer;
    height: 30px;
    padding: 0;
    transform: rotate(-45deg);
    width: 30px;

    &:after {
      content: "";
      background: $neutral-700;
      border-radius: 50%;
      height: 14px;
      left: 0;
      margin: 7px;
      position: absolute;
      top: 0;
      width: 14px;
    }

    &___violet {
      background: $violet-400 !important;
    }

    &___green {
      background: $green-400 !important;
    }

    &___red {
      background: $red-400 !important;
    }
  }
}