@import '../../../scss/variables';

.TimesheetCarousel {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &_boldText {
    font-weight: 700;
  }

  &_separator {
    border: 1px solid $border-300;
    margin: 10px auto;
    width: 130px;
  }

  &_projectName {
    color: $neutral-400;
    font-weight: 700;

    &___isProjectTimesheet {
      color: $violet-400;
    }
  }

  &_noMargin {
    margin: 0 !important;
  }

  &_entry {
    text-align: left;
  }

  &_carousel {
    &_buttons {
      align-items: center;
      background-color: $blue-50;
      display: flex;
      justify-content: space-between;
      margin: 20px -8px -8px;
      padding: 5px;
    }

    &_button {
      background-color: $blue-400;
      border: 0;
      border-radius: 4px;
      color: $neutral-0;
      cursor: pointer;
      outline: none;
      padding: 10px;
    }
  }

  &_actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    &_action {
      color: $neutral-400;
      margin-left: 8px;
      padding: 0;
    }
  }

  &_informationGroup {
    margin-bottom: 15px;
    width: 100%;
  }

  &_informationGrid {
    grid-column-gap: 10px;
    grid-template-columns: 0.8fr 1fr;
    margin-bottom: 5px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}