@import '../../../scss/variables';
@import '../../../scss/mixins';

.Rosters {
  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &_secondRow {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 93px;

    @include breakpoint(sm) {
      margin-top: 143px;
    }

    &_item {
      margin-right: 16px;
      width: auto !important;

      @include breakpoint(xs) {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0;
      }

      &_buttonText {
        @include breakpoint(xs) {
          display: none;
        }
      }

      i {
        @include breakpoint(xs) {
          margin: 0;
        }
      }
    }
  }

  &_thirdRow {
    display: grid;
    grid-template-columns: 1fr;

    &___active {
      grid-template-columns: 0.3fr 1.4fr 0.3fr;
      grid-column-gap: 20px;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr;
    }
  }

  &_noEmployees {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    text-align: center;

    &_image {
      margin-bottom: 60px;
      max-width: 350px;
    }

    &_withMargin {
      margin-top: 10px;
    }

    &_link {
      color: $blue-400;
    }
  }
}