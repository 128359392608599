@import '../../../../scss/mixins';

.ButtonGroup {
  &___horizontal {
    display: flex;
    justify-content: flex-end;
    margin: 32px 0 0 0;
    @include breakpoint(sm) {
      flex-direction: column-reverse;
    }

    &_button {
      min-width: 144px;

      &:nth-child(2) {
        margin: 0 0 0 16px;

        @include breakpoint(sm) {
          margin: 0 0 8px 0;
        }
      }
    }
  }
&___fullsize {
    display: flex;
    justify-content: flex-end;
    margin: 32px 0 0 0;
    
    @include breakpoint(sm) {
      flex-direction: column-reverse;
    }

    &_button {
      min-width: 144px;
      width: 100%;
      &:nth-child(2) {
        margin: 0 0 0 16px;

        @include breakpoint(sm) {
          margin: 0 0 8px 0;
        }
      }
    }
  }
  &___vertical {
    display: flex;
    flex-direction: column;

    &_button {
      &:nth-child(2) {
        margin: 8px 0 0 0;
      }
    }
  }
}
