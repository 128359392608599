@import '../../scss/mixins';

.Schedule {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 250px;
  grid-gap: 2px;

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 240px 240px 240px 240px;
  }

  @include breakpoint(xs) {
    grid-template-columns: 1fr;
    grid-template-rows: 240px 240px 240px 240px 240px 240px 240px;
  }

  &_error {
    margin: 8px 0;
    width: max-content;
  }
}