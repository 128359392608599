@import '../../../../scss/mixins';

.Preloader {
  @include ease(0.4s);
  height: 650px;
  margin-top: 20px;
  max-width: 450px;
  overflow: hidden;

  &_shine {
    background-size: 800px 650px;
    height: 650px;
  }
}