@import '../../../scss/variables';
@import '../../../scss/mixins';

.Map {
  &_firstRow {
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;
  }

  &_secondRow {
    align-items: center;
    display: flex;
    margin-top: 93px;

    @include breakpoint(lg) {
      flex-direction: column;
    }
  }

  &_navigationContainer {
    align-items: center;
    display: flex;

    @include breakpoint(lg) {
      margin-bottom: 24px;
      flex-direction: column;
    }

    &_navigation {
      align-items: center;
      display: flex;
      margin-left: 24px;

      @include breakpoint(lg) {
        margin-top: 24px;
      }

      @include breakpoint(xs) {
        margin-left: 0;
      }

      &_item {
        margin-right: 16px !important;
        width: auto !important;

        @include breakpoint(xs) {
          margin-right: 8px !important;
        }

        &:last-child {
          margin-right: 0 !important;
        }

        &_buttonText {
          @include breakpoint(xs) {
            display: none;
          }
        }

        i {
          @include breakpoint(xs) {
            margin: 0;
          }
        }
      }
    }
  }

  &_thirdRow {
    border-top: 1px solid $border-300;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;

    @include breakpoint(sm) {
      flex-direction: column;
      text-align: center;
    }

    &_checkboxes {
      align-items: center;
      column-gap: 20px;
      display: flex;
      margin-top: 10px;

      @include breakpoint(sm) {
        justify-content: center;
      }
    }

    &_viewSpecificEmployee {
      min-width: 220px;

      @include breakpoint(sm) {
        margin: 10px auto 0;
        max-width: 220px;
      }
    }
  }

  &_mapContainer {
    border: 2px solid $violet-400;
    height: 500px;
    margin-top: 40px;
    width: 100%;
  }

  &_timesheet {
    border: 1px solid $green-400;
    border-radius: 4px;
    padding: 10px;

    &___done {
      border-color: $red-400;
    }

    &_group {
      margin-top: 10px;
    }
  }
}