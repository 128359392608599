@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.AccountType {
  max-width: 650px;
  text-align: center;
  width: 100%;

  @include breakpoint(xs) {
    max-width: 450px;
  }

  &_header {
    padding: 20px;

    &_logo {
      width: 200px;
    }

    &_headingTextWrapper {
      border-bottom: 1px solid $neutral-200;
      margin: 20px 0;
      text-align: center;

      &_headingText {
        background-color: $neutral-0;
        bottom: -8px;
        padding: 0 10px;
        position: relative;
      }
    }
  }

  &_content {
    text-align: left;
    padding: 0 20px 20px;

    &_radioGroup {
      &_inner {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        
        @include breakpoint(xs) {
          flex-direction: column;
        }
      }
    }
  }
}