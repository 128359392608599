@import '../../../scss/variables';
@import '../../../scss/mixins';

.Rosters {
  &_firstRow {
    align-items: center;
    background: $neutral-0;
    box-shadow: $shadow-normal;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 20px 30px;
    position: fixed;
    right: 0;
    top: 65px;
    z-index: 2;

    @include breakpoint(sm) {
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &_secondRow {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 93px;

    &_item {
      margin-right: 16px;
      width: auto !important;

      @include breakpoint(xs) {
        margin-right: 8px;
      }

      &:last-child {
        margin-right: 0;
      }

      &_buttonText {
        @include breakpoint(xs) {
          display: none;
        }
      }

      i {
        @include breakpoint(xs) {
          margin: 0;
        }
      }
    }
  }

  &_noEmployees {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    text-align: center;

    &_withMargin {
      margin-top: 10px;
    }

    &_link {
      color: $blue-400;
    }
  }
}